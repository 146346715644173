<template>
  <v-app>
      <Cobija/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Cobija from '@/components/Cobija.vue'

export default {
  name: 'CobijaView',
  components: {
    Cobija
  }
}
</script>

